
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 16rem;
  width: 16rem;
  height: 100%;
  background-color: var(--color-secondary);
  display: none;
}

@media (min-width: 768px) {
  .sidebar {
    display: flex;
    flex-direction: column;
  }
}

.sidebarLogo {
  @apply cursor-pointer;
  @apply my-4;
  color: var(--color-text) !important;
  width: 215px;
  height: 48px;
}

.sidebarContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

.sidebarItem {
  @apply flex;
  @apply my-2;
  @apply mx-3;
  @apply py-2;
  @apply px-3;
  @apply items-center;
  @apply cursor-pointer;
}

.sidebarItem:hover {
  background-color: var(--color-buttons);
  transition: background-color 0.3s ease;
}

.sidebarIcon {
  @apply w-6;
  @apply h-6;
  @apply mr-4;
}

.sidebarFooter {
  @apply p-3;
  @apply mt-2;
  @apply items-center;
  @apply opacity-50;
  font-size: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.smallLink {
  @apply cursor-pointer;
}
